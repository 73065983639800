import {
  ModuleWithProviders,
  NgModule,
} from '@angular/core';
import { MatCardModule } from '@angular/material/card';

import { TranslateModule } from '@ngx-translate/core';

import { UserSidebarComponent } from './components';
import { UserLayoutComponent } from './layout/user-layout.component';
import { UserDashboardComponent } from './pages';
import { UserRoutingModule } from './user-routing.module';

import { SharedModule } from '../shared/shared.module';

const APP_COMPONENTS = [
  UserLayoutComponent,
  UserSidebarComponent,
  UserDashboardComponent,
];

@NgModule({
  imports: [
    SharedModule.forRoot(),
    UserRoutingModule,
    MatCardModule,
    TranslateModule,
  ],
  declarations: [
    ...APP_COMPONENTS,
  ],
  exports: [
    ...APP_COMPONENTS,
  ],
})
export class UserModule {
  static forRoot(): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
    };
  }
}
