import { BreakpointObserver } from '@angular/cdk/layout';
import {
  AfterContentChecked,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

import { Subscription } from 'rxjs';

import {
  AppService,
  DrawerModeMaxWidth,
} from '../../core';
import { LayoutService } from '../../shared';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss'],
  standalone: false,
})
export class UserLayoutComponent implements OnDestroy, AfterViewInit, AfterViewChecked, AfterContentChecked {
  @ViewChild('drawer') drawer: MatDrawer;

  headerButtons: TemplateRef<any> | null = null;
  expanded = this.appService.expanded;
  sideBarStateSubscription: Subscription;
  layoutServiceSubscription: Subscription;

  constructor(
    private appService: AppService,
    private observer: BreakpointObserver,
    private cdr: ChangeDetectorRef,
    private layoutService: LayoutService
  ) {
    this.sideBarStateSubscription = this.appService.sideBarExpandedChange.subscribe(expanded => {
      this.expanded = expanded;
      this.drawer.toggle(this.expanded);
    });

    this.layoutServiceSubscription = this.layoutService.headerButtons$.subscribe(buttons => {
      this.headerButtons = buttons;
    });
  }

  ngAfterViewInit() {
    this.observer.observe([`(max-width: ${DrawerModeMaxWidth}px)`])
      .subscribe(res => res.matches ? this.drawer.mode = 'push' : this.drawer.mode = 'side');
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.sideBarStateSubscription.unsubscribe();
  }

  toggleSidebar(expanded: boolean = !this.expanded) {
    this.expanded = expanded;
    this.appService.expanded = this.expanded;
  }
}
