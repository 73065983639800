import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import {
  Subject,
  takeUntil,
} from 'rxjs';

import {
  User,
  UserRoles,
  UserService,
} from '../../../core';
import { MenuRow } from '../../../shared/components';

@Component({
  selector: 'app-user-sidebar',
  template: '<app-sidebar [menu]="menu"></app-sidebar>',
  standalone: false,
})
export class UserSidebarComponent implements OnInit, OnDestroy {
  public menu: MenuRow[];
  private destroy$ = new Subject<void>();

  constructor(private userService: UserService) {
  }

  ngOnInit() {
    this.userService.currentUser
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.initializeMenu(user);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private initializeMenu(user: User) {
    this.menu = [
      {
        id: 'dashboard',
        title: 'pages.dashboard',
        link: '/dashboard',
        icon: 'dashboard',
      },
      {
        id: 'actionLogs',
        title: 'pages.actionLogs',
        link: '/action-logs',
        icon: 'list_alt',
      },
      {
        id: 'teams',
        title: 'pages.teams.list',
        link: '/teams',
        icon: 'group',
        isHidden: user.role === UserRoles.CUSTOMER,
      },
      {
        id: 'projects',
        title: 'pages.projects.list',
        link: '/projects',
        icon: 'work',
        isHidden: user.role === UserRoles.CUSTOMER,
      },
      {
        id: 'tickets',
        title: 'pages.tickets.list',
        link: '/tickets',
        icon: 'assignment',
      },
    ];
  }
}
